

function Banner() {
    return (
        <div className="w-full bg-gray-400">
            <div className="max-w-screen mx-auto">
                <img src="./img/banner.png" alt="banner" className="w-full" />
            </div>
        </div>



    )
}

export default Banner